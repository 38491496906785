import React, { useEffect, useState } from 'react'
import { listUser } from '../../services/api';
import { BarLoader } from "react-spinners";

import './User.css';

function User() {

  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    (async () => {
      const response = await listUser();
      const users = response.data;
      setUser(users);
      setLoading(false);
    })();
  }, []);

  return (
    <div className="users">
      <h4>Usuários do Sistema</h4>
      <hr />
      {loading ? (
        <div className="loading-spinner">
          <BarLoader color="#36D7B7" loading={loading} />
          <p>Carregando...</p>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Tipo de usuário</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {user.map((user) => (
              <tr key={user.user_id}>
                <td>{user.username}</td>
                <td>{user.role_name}</td>
                <td>{user.status}</td>
                <td>
                  <button className="updateBtn">edit</button>
                  <button className="deleteBtn">delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default User