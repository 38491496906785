import emailjs from 'emailjs-com';
// import storePdf from '../Pdf/StorePdf';

const sendEmail = (store) => {
  // Substitua esses valores pelos seus próprios credenciais de serviço de e-mail
  const serviceId = 'service_1xlpaaq';
  const templateId = 'template_o7cnhvo';
  const userId = 'TPtEE79QVob4NacPc';

  // Configurar os parâmetros do e-mail
  const emailParams = {
    to_email: 'alessandrodigima@gmail.com',
    subject: 'Envio de relatório',
    message: ` via sistema A.I.C.A.A:
    
     Nome do CEI: ${store.store_name}                                             
   
     CNPJ: ${store.store_cnpj}
     
     ${store.banner_url}
     
     `

  };

  // Configurar o corpo do e-mail
  const emailBody = {
    service_id: serviceId,
    template_id: templateId,
    user_id: userId,
    to_email: emailParams.to_email,
    subject: emailParams.subject,
    message: emailParams.message,
  };

  // Adicionar o anexo (PDF) ao corpo do e-mail
  emailBody['attachment'] = {
    // data: pdfBlob,
    content_type: 'application/pdf',
    name: 'document.pdf',
  };

  // Enviar o e-mail usando emailjs-com
  emailjs.send(serviceId, templateId, emailBody, userId)
    .then((response) => {
      // console.log('E-mail enviado com sucesso:', response);
      alert('E-mail enviado com sucesso');

    })
    .catch((error) => {
      console.error('Erro ao enviar e-mail:', error);
      alert('Falha ao enviar o email');
    });
};

const storeEmail = (store) => {
  // const pdfBlob = storePdf(store);
  sendEmail(store);
};

export default storeEmail;
