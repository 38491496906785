import React, { useRef } from 'react';
import '../Banner/Banner.css';


import Carousel from '../../../components/Carousel/Carousel';

function Banner() {
  const banner = useRef(null);

  return (
    <div ref={banner} id='banner' className='banner'>
      <Carousel />
    </div>
  )
}

export default Banner