import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { FcConferenceCall, FcStatistics } from "react-icons/fc";
import { FcReading } from "react-icons/fc";


import '../Welcome.css'




function Aside({ onComponentClick }) {

  return (
    <>
      <aside>
        {/* <ul>

          <li className='asideLink' onClick={() => onComponentClick("CardStore")} >
            <div>
              <img  className='asideIco' className='asideIco'src="./img/aicaa.png" alt="aside ico" />
            </div>
            <div>
              <span>CEIs</span>
            </div>
          </li>


          <li className='asideLink'>
            <div>
              <img  className='asideIco'src="./img/aicaa.png" alt="aside ico" />
            </div>
            <div>
              <span>Contratos</span>
            </div>
          </li>


          <li className='asideLink'>
            <div>
              <img  className='asideIco'src="./img/aicaa.png" alt="aside ico" />
            </div>
            <div>
              <span>Relatórios</span>
            </div>
          </li>
        </ul> */}
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='asideLink'>
            <div>
              {/* <img className='asideIco' src="./img/aicaa.png" alt="aside ico" /> */}
              <FcReading className='asideIco' />
            </div>
            <div>
              <span>CEIs</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onComponentClick("GetStore")}>
              <span className='asideLink'>Consultar CEIs</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onComponentClick("CreateStore")}>
              <span className='asideLink'>Cadastrar novo CEI</span>
            </Dropdown.Item>
            {/* Adicione outros itens do dropdown conforme necessário */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='asideLink'>
            <div>
              <FcConferenceCall className='asideIco' />
            </div>
            <div>
              <span>Usuários</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onComponentClick("User")}>
              <span className='asideLink'>Usuários do Sistema</span>
            </Dropdown.Item>
            {/* Adicione outros itens do dropdown conforme necessário */}
            {/* <Dropdown.Item onClick={() => onComponentClick("UpdateStore")}>
              <span className='asideLink'>UpdateStore</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onComponentClick("ShowStore")}>
              <span className='asideLink'>Show Store</span>
            </Dropdown.Item> */}
            <Dropdown.Item>
            </Dropdown.Item>
            <span className='asideLink'> <a href="/multerupload">Multer - Upload</a></span>

            <Dropdown.Item>
            </Dropdown.Item>
            <span className='asideLink'> <a href="../proposta-01/index.html">Proposta - 01 - AICAA</a></span>

          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='asideLink'>
            <div>
              <FcStatistics className='asideIco' />
            </div>
            <div>
              <span>Pedidos</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onComponentClick("FreeMarketOrder")}>
              <span className='asideLink'>Solicitar Feira</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onComponentClick("FreeMarketOrder")}>
              <span className='asideLink'>Solicitar Materiais</span>
            </Dropdown.Item>
            {/* Adicione outros itens do dropdown conforme necessário */}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className='asideLink'>
            <div>
              <FcStatistics className='asideIco' />
            </div>
            <div>
              <span>Convênios</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => onComponentClick("GetStoreContract")}>
              <span className='asideLink'>Consultar Convênios</span>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onComponentClick("CreateContract")}>
              <span className='asideLink'>Cadastrar novo convênio</span>
            </Dropdown.Item>
            {/* Adicione outros itens do dropdown conforme necessário */}
          </Dropdown.Menu>
        </Dropdown>

      </aside>
    </>
  )
}

export default Aside