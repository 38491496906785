import React from 'react'

import './Flag.css';

function Flag(props) {

  return (

    <>
      <div className="flag">
        <div className="flagBody">
          <a className="flagBody" href={props.propsLink}>
            <div className="colA">
              <img src={props.ico} alt="" />
            </div>
            <div className="colB">
              <span>{props.text}</span>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default Flag