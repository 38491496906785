import React from 'react'

import './ConfirmationModal.css';

function ConfirmationModal({ message, onConfirm, onCancel }) {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <p>{message}</p>
          <button onClick={onConfirm}>Sim</button>
          <button onClick={onCancel}>Não</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal