import React, { useEffect, useState } from 'react'
import { BarLoader } from "react-spinners";

import '../Store/styles/Store.css';

import { getStore } from '../../services/api';
import { deleteStore } from '../../services/api';
import SearchBar from '../Welcome/components/SearchBar';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import storePdf from '../../utils/Pdf/StorePdf';
import storeEmail from '../../utils/Email/StoreEmail';

function GetStore({ onComponentClick, onComponentStoreClick }) {
  const [stores, setStores] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filteredStore, setFilteredStore] = useState([]);
  const [, setStoreIdToUpdate] = useState("");
  const [, setStoreIdToShow] = useState("");
  const [storeIdToDelete, setStoreIdToDelete] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getStore();
      const storeData = response.data;
      // console.log(storeData)
      setStores(storeData);
      setLoading(false);
      // Atualize os resultados filtrados quando o searchText for alterado
      const filteredStores = getFilteredStores();
      setFilteredStore(filteredStores);
    })();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (store_id) => {
    setStoreIdToDelete(store_id);
    setMessage(`Tem certeza que deseja remover o registro: ${storeIdToDelete}`);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Chama a função deleteStore da API
      await deleteStore(storeIdToDelete);
      setShowModal(true);
      setMessage(`Removido o registro ID: ${storeIdToDelete} com sucesso :)`);
      // Atualiza a lista após a exclusão
      setStores((prevStores) => prevStores.filter((store) => store.store_id !== storeIdToDelete));
      setShowModal(false);
    } catch (error) {
      setShowModal(true);
      console.error('Erro ao excluir:', error);
      setMessage(`Erro ao tentar deletar o registro: ${error}`);
      setShowModal(false);
    }
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };


  const handleUpdate = async (store_id) => {
    const id = store_id;
    setStoreIdToUpdate(id);
    onComponentClick(id);
  };

  const handleShowStore = async (store_id) => {
    const id = store_id;
    setStoreIdToShow(id);
    onComponentStoreClick(id); // Invoke the callback with the selected store_id
  };


  // Lógica para calcular os índices dos itens a serem exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Lógica para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Função para adicionar uma classe de estilo condicional ao botão da página atual
  const getPageButtonClass = (index) => (currentPage === index + 1 ? 'active' : '');

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    // Atualize os resultados filtrados quando o searchText for alterado
    const filteredStores = getFilteredStores();
    setFilteredStore(filteredStores);
  };

  const getFilteredStores = () => {
    return stores.filter((store) => {
      const storeInfo = `${store.store_id} ${store.store_name} ${store.fantasy_name} ${store.store_cnpj} ${store.street}`;
      const searchTextLowerCase = searchText.trim().toLowerCase();
      return storeInfo.toLowerCase().includes(searchTextLowerCase);
    });
  };

  const handlePdf = (store, stores) => {
    storePdf(store, stores);
  };

  const handleEmail = (pdfBlob) => {
    storeEmail(pdfBlob);
  };

  return (
    <>
      {showModal && (
        <ConfirmationModal
          message={message}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      <SearchBar onSearch={handleSearch} />
      <h4>CEIs - Centros de Educação Infantil</h4>
      <section className="stores">
        <table>
          <thead>
            <tr>
              {/* <th>Razão Social</th> */}
              <th>CEI - Nome Fantasia</th>
              <th>cnpj</th>
              <th>Contato</th>
              <th>Telefone</th>
              {/* <th>Contato 2</th> */}
              <th>Telefone 2</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            {loading ? (

              <tr>
                <td colSpan="4" className="loading-spinner">
                  <BarLoader color="#36D7B7" loading={loading} />
                  <p>Carregando...</p>
                </td>
              </tr>

            ) : (
              (searchText === '' ? stores : filteredStore).slice(indexOfFirstItem, indexOfLastItem).map((store) =>
              (

                <tr key={store.store_id} className='listlinBtn'>
                  {/* <td><a href="/">{store.store_name}</a></td> */}
                  <td><button title="Clique para ver detalhes" type="button" className='listLinkBtn' onClick={() => handleShowStore(store.store_id)}>{store.fantasy_name}</button></td>
                  <td>{store.store_cnpj}</td>
                  <td>{store.contact}</td>
                  <td>{store.phone}</td>
                  {/* <td><a href="/">{store.contact2}</a></td> */}
                  < td > {store.phone2}</td>
                  <td>{store.email}</td>
                  <td className='tdBtn'>
                    <button className='updateBtn' onClick={() => handleUpdate(store.store_id)}>editar</button>
                    <button className='deleteBtn' onClick={() => handleDelete(store.store_id)}>excluir</button>
                    <button className='pdfBtn' onClick={() => handlePdf(store, stores)}>pdf</button>
                    <button className='emailBtn' onClick={() => handleEmail(store)}>email</button>
                  </td>
                </tr>
              )
              ))}
          </tbody>
        </table >
        <div className="pagination">
          {Array.from({ length: Math.ceil((searchText === '' ? stores.length : filteredStore.length) / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
              {index + 1}
            </button>
          ))}
        </div>
      </section >

      <section id="storesMobile" className="storesMobile">
        {loading ? (
          <ul>
            <li className="loading-spinner">
              <BarLoader color="#36D7B7" loading={loading} />
              <p>Carregando...</p>
            </li>
          </ul>
        ) : (
          (searchText === '' ? stores : filteredStore).slice(indexOfFirstItem, indexOfLastItem).map((store) =>
          (
            <ul key={store.store_id}>
              <li>
                <span>Unidade:</span> <span>{store.store_name}</span>
              </li>
              <li>
                <span>CNPJ:</span> <span>{store.store_cnpj}</span>
              </li>
              <li>
                <span>Contato:</span> <span>{store.contact}</span>
              </li>
              <li>
                <span>Telefone:</span> <span>{store.phone}</span>
              </li>
              <li>
                <span>Contato:</span> <span>{store.contact2}</span>
              </li>
              <li>
                <span>Telefone:</span> <span>{store.phone2}</span>
              </li>
              <li>
                <span>Email:</span> <span>{store.email}</span>
              </li>
              <li>
                <button className='updateBtn' onClick={() => handleUpdate(store.store_id)}>editar</button>
                <button className='deleteBtn' onClick={() => handleDelete(store.store_id)}>excluir</button>
              </li>
            </ul>
          )
          ))}
        <div className="pagination">
          {Array.from({ length: Math.ceil((searchText === '' ? stores.length : filteredStore.length) / itemsPerPage) }, (_, index) => (
            <button key={index + 1} onClick={() => paginate(index + 1)} className={getPageButtonClass(index)} >
              {index + 1}
            </button>
          ))}
        </div>
      </section >
    </>
  )
}

export default GetStore