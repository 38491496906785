import React from 'react'

import './CardMobile.css'

function CardMobile(props) {
  return (
    <>
      <section className="contentMobile">
        <div className="cardMobile">
          <div className="cardheadBkgMobile"></div>
          <div className="cardheadMobile">
            <img src="img/aicaalogo.ico" alt="aicaa.logo" />
            <h4>{props.nomeDoCei}</h4>
          </div>
          <div className="cardCnpjMobile">{props.cnpj}</div>
          <section className='cardBlockMobile'>
            <div className='cardRowMobile addressRow'>
              <span className="address">Endereço </span>
              <div className="address">
                <a href={`https://www.google.com/maps?q=${props.address}`}
                  target="_blank"
                  rel="noopener noreferrer">{props.address}</a>
              </div>
            </div>
            <section className="cardBlockMobile">
              <div className="cardRowMobile">
                <span className=''>Telefones </span>
                <div className="cardBlockMobile phoneNumber">
                  <div className='phoneNumberItem'>{props.phoneNumber1}</div>
                  <div className='phoneNumberItem'>{props.phoneNumber2}</div>
                  <div className='phoneNumberItem'>{props.phoneNumber3}</div>
                  <div className='phoneNumberItem'>{props.phoneNumber4}</div>
                </div>
              </div>
            </section>
            <section className="cardBlockMobile">
              <div className="cardRowMobile">
                <span>Email </span>
                <div className="emailMobile">
                  <a href={`mailto:${props.email}`}>{props.email}</a>
                </div>
              </div>
            </section>

            <section className="cardBlockMobile">
              <div className="cardRow">
                <span>Contatos</span>
              </div>
              <div className="cardRowMobile">
                <div className="contactMobile">
                  <img src="img/avatar.png" alt="avatar" />
                  <span htmlFor="">{props.contact}</span>
                </div>
                <div className='contactLinkMobile'>
                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/whatsappIco.png" alt="whatsapp icon" /></a>

                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/phoneIco.png" alt="phoneNumber icon" /></a>

                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/emailIco.png" alt="email icon" /></a>
                </div>
              </div>

              <div className="cardRowMobile">
                <div className="contactMobile">
                  <img src="img/avatar.png" alt="avatar" />
                  <span htmlFor="">{props.contact2}</span>
                </div>
                <div className='contactLinkMobile'>
                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/whatsappIco.png" alt="whatsapp icon" /></a>

                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/phoneIco.png" alt="phoneNumber icon" /></a>

                  <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                    target="_blank"
                    rel="noopener noreferrer"><img className='contactIcoMobile' src="./img/emailIco.png" alt="email icon" /></a>
                </div>
              </div>

            </section>

            <section className="cardBlockMobile">
              <div className="cardRowMobile">
                <span>Atendimentos desta unidade</span>
              </div>
              <section className="servicesMobile">
                <div className="serviceType">Total de Atendimentos: {props.totalServices}</div>
                <div className="serviceTypeItems">
                  <div className="serviceType">{props.serviceName2}: {props.totalServiceName1} </div>
                  <div className="serviceType">{props.serviceName2}: {props.totalServiceName2} </div>
                  <div className="serviceType">{props.serviceName3}: {props.totalServiceName3} </div>
                </div>
              </section>
            </section>
          </section>
        </div>
      </section>
    </>
  )
}

export default CardMobile