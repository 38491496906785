import React, { useEffect, useState } from 'react';
import { BarLoader } from "react-spinners";
import axios from "axios";

import '../Store/styles/Store.css';

import { getCompany, createStore } from '../../services/api';
import ModalForms from '../Welcome/components/ModalForms';

function CreateStore() {

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const [message, setMessage] = useState("");
  const [messageCep, setMessageCep] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [, setStoreName] = useState('');
  const [selectedLogo, setSelectedLogo] = useState('./img/logo_default.png');
  const [file, setFile] = useState("");

  const [formData, setFormData] = useState({
    store_name: "",
    company_id: "",
    fantasy_name: "",
    company_type: "filial",
    cnpj: "",
    inscEstadual: "",
    inscMunicipal: "",
    contact: "",
    contact2: "",
    phone: "",
    phone2: "",
    mobile: "",
    mobile2: "",
    website: "",
    email: "",
    socialMedia: "",
    socialMedia2: "",
    socialMedia3: "",
    banner_url: "",
    address: {
      zipcode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: ""
    }
  });

  useEffect(() => {
    (async () => {
      const response = await getCompany();
      const company = response.data;
      setCompanies(company);
      setLoading(false);
    })();
  }, []);

  const handleOptionChange = (e) => {
    e.preventDefault();

    const selectedOption = e.target.options[e.target.selectedIndex];
    const companyId = selectedOption.getAttribute('data-id');
    const companyName = selectedOption.getAttribute('data-name');

    setSelectedCompanyId(companyId);
    setStoreName(companyName);

    setFormData((prevData) => ({
      ...prevData,
      company_id: companyId,
      store_name: companyName,
    }));
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const setImgFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFile(file);

    if (file) {
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file); // Alteração aqui
    }
  };


  const handleCEPChange = (e) => {
    // Handle CEP change
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value
      }
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submission", formData);

    // Verificar os dados do formulário antes de enviar
    if (formData.store_name === "" || formData.store_name === null || formData.store_name === undefined) {
      console.error("O campo 'Razão Social' é obrigatório.");
      return;
    }


    // Adicione outras verificações conforme necessário

    try {
      // Envie os dados do formulário para o servidor
      const response = await createStore(formData);
      console.log("Resposta do servidor:", response.data);
      setMessage("Formulário enviado com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 1500)
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      setMessage("Erro ao enviar formulário. Tente novamente mais tarde.");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 1500)
    }
  };

  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Conteúdo da sua modal */}
            {message && <ModalForms message={message} onClose={() => setShowModal(false)} />}
          </div>
        </div>)}
      <h4>Cadastrar novo CEI</h4>
      <section className="createStores">
        <form className="createStore" encType="multipart/form-data" onKeyDown={handleKeyDown}>
          <div className="form-group">
            <h4>Dados da empresa</h4>
            <div className="group">
              <div className='colA'><label htmlFor="store_name">Matriz - Razão Social</label></div>
              <div className='colB'>
                {loading ? (
                  <div className="loading-spinner">
                    <BarLoader color="#36D7B7" loading={loading} />
                    <p>Carregando...</p>
                  </div>
                ) : <select
                  className='selectList'
                  id='company'
                  value={selectedCompanyId}
                  onChange={handleOptionChange}
                  required
                >
                  <option value="">Selecione uma empresa</option>
                  {companies.map((company) => (
                    <option
                      key={company.company_id}
                      value={company.company_id}
                      data-id={company.company_id}
                      data-name={company.company_name}
                    >
                      {company.company_name}
                    </option>
                  ))}
                </select>
                }

              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="store_name">Razão Social</label></div>
              <div className='colB'>
                <input type="text"
                  id='store_name'
                  name="store_name"
                  onChange={handleChange}
                  required />
              </div>
            </div>
            <div className="group">
              <div className='colA'>
                <button className='chooseFileBtn'>
                  <label htmlFor="banner_url">Selecione: Logo / Foto / Banner</label>
                </button>

              </div>
              <div className='colB'>
                <input
                  type="file"
                  className="banner_url"
                  id="banner_url"
                  name="banner_url"
                  onChange={setImgFile}
                />
                <img className='selectedLogo' src={selectedLogo} alt="logotipo" />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="fantasy_name">Nome Fantasia</label></div>
              <div className='colB'><input type="text" id='fantasy_name' name="fantasy_name" onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="cnpj">CNPJ</label></div>
              <div className='colB'>
                <input
                  mask="99.999.999/9999-99"
                  name='cnpj'
                  type="text"
                  id="cnpj"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="inscEstadual">Inscrição Estadual</label></div>
              <div className='colB'><input type="text" id='inscEstadual' name='inscEstadual' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="cnpj">Inscrição Municipal</label></div>
              <div className='colB'><input type="text" id='inscMunicipal' name='inscMunicipal' onChange={handleChange} /></div>
            </div>
          </div>

          <div className="form-group">
            <h4>Contatos</h4>
            <div className="group">
              <div className='colA'><label htmlFor="contact">Contato</label></div>
              <div className='colB'><input type="text" id='contact' name='contact' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="phone">Telefone</label></div>
              <div className='colB'>
                <input
                  mask="(99) 9999-9999"
                  name='phone'
                  type="text"
                  id="phone"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="mobile">Celular</label></div>
              <div className='colB'>
                <input
                  mask="(99) 99999-9999"
                  name="mobile"
                  type="text"
                  id="mobile"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="contact2">Contato 2</label></div>
              <div className='colB'><input type="text" id='contact2' name='contact2' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="phone2">Telefone 2</label></div>
              <div className='colB'>
                <input
                  mask="(99) 9999-9999"
                  name='phone2'
                  type="text"
                  id="phone2"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="mobile2">Celular 2</label></div>
              <div className='colB'>
                <input
                  mask="(99) 99999-9999"
                  name='mobile2'
                  type="text"
                  id="mobile2"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <h4>Web</h4>
            <div className="group">
              <div className='colA'><label htmlFor="website">Website</label></div>
              <div className='colB'><input type="text" id='website' name='website' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="email">Email</label></div>
              <div className='colB'><input type="email" id='email' name='email' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia' name='socialMedia' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia2">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia2' name='socialMedia2' onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia3">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia3' name='socialMedia3' onChange={handleChange} /></div>
            </div>
          </div>

          <div className="form-group">
            <h4>Endereço</h4>
            <div className="group">
              <div className='colA'><label htmlFor="zipcode">CEP</label></div>
              <div className='colB'>
                <input

                  type="text"
                  id='zipcode'
                  name='zipcode'
                  onChange={(e) => {
                    handleCEPChange(e);
                    handleAddressChange(e);
                  }}
                />
                {messageCep && <div className="message">{messageCep}</div>}
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="street">Endereço</label></div>
              <div className='colB'>
                <input
                  value={formData.address.street}
                  type="text" id='street'
                  name='street'
                  onChange={handleAddressChange}
                /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="number">Número</label></div>
              <div className='colB'>
                <input
                  value={formData.address.number}
                  type="text"
                  id='number'
                  name='number'
                  onChange={handleAddressChange}
                /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="complement">Complemento</label></div>
              <div className='colB'>
                <input
                  value={formData.address.complement}
                  type="text"
                  id='complement'
                  name='complement'
                  onChange={handleAddressChange}
                /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="neighborhood">Bairro</label></div>
              <div className='colB'>
                <input
                  value={formData.address.neighborhood}
                  type="text"
                  id='neighborhood'
                  name='neighborhood'
                  onChange={handleAddressChange}
                /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="city">Cidade</label></div>
              <div className='colB'>
                <input
                  value={formData.address.city}
                  type="text"
                  id='city'
                  name='city'
                  onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="state">Estado</label></div>
              <div className='colB'>
                <input
                  value={formData.address.state}
                  type="text"
                  id='state'
                  name='state'
                  onChange={handleAddressChange}
                />
              </div>
            </div>
          </div>
          <div className="btnForm">
            <button className="bntForm" onClick={handleFormSubmit} >Enviar</button>
          </div>
          {/* {message && <div className="message">{message}</div>} */}
        </form>
      </section >
    </>
  )
}

export default CreateStore