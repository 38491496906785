import React, { useEffect, useState } from "react";
import { getStore, getStoreContract } from '../../services/api';
import { BarLoader } from "react-spinners";

import '../Welcome/Welcome.css';

import NavBar from './components/NavBar';
import Aside from "./components/Aside";
import MenuMobile from '../../components/MenuMobile/MenuMobile';
import User from "../User/User";
import GetStore from "../Store/GetStore";
import CreateStore from "../Store/CreateStore";
import UpdateStore from "../Store/UpdateStore";
import ShowStore from "../Store/ShowStore";
import GetStoreContract from "../Contract/GetStoreContract";
import CreateContract from "../Contract/CreateContract";
import FreeMarketOrder from "../FreeMarket/CreateFreeMarketOrder";


function Welcome() {
  const [selectedComponent, setSelectedComponent] = useState("");
  const [, setStores] = useState([]);
  const [, setStoreContract] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storeIdToUpdate, setStoreIdToUpdate] = useState("");
  const [storeIdToShow, setStoreIdToShow] = useState(null);



  useEffect(() => {
    (async () => {
      const response = await getStore();
      const storesData = response.data;
      setStores(storesData);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getStoreContract();
      const contractData = response.data;
      setStoreContract(contractData);
      setLoading(false);
    })();
  }, []);

  const handleComponentClick = (component) => {
    setSelectedComponent(component);
  };

  const handleUpdate = async (store_id) => {
    setSelectedComponent("UpdateStore");
    setStoreIdToUpdate(store_id);
  };

  const handleShowStore = async (store_id) => {
    setSelectedComponent("ShowStore");
    setStoreIdToShow(store_id);
  };

  const handleUpdateSuccess = (store_id) => {
    // Esta função será chamada por UpdateStore após a atualização bem-sucedida
    // Aqui você pode atualizar os detalhes da loja ou fazer qualquer outra ação necessária
    console.log(`Loja atualizada com sucesso! StoreId: ${store_id}`);
    // Faça o que for necessário aqui, por exemplo, buscar novamente os detalhes da loja
    setStoreIdToShow(store_id);
    setSelectedComponent("ShowStore");
  };
  const renderComponent = () => {
    switch (selectedComponent) {
      case 'User':
        return <User />;
      case 'GetStore':
        return <GetStore onComponentClick={handleUpdate} onComponentStoreClick={handleShowStore} />
      case 'CreateStore':
        return <CreateStore />;
      case 'CreateContract':
        return <CreateContract />;
      case 'GetStoreContract':
        return <GetStoreContract />;
      case 'UpdateStore':
        return <UpdateStore storeIdToUpdate={storeIdToUpdate} onUpdateSuccess={handleUpdateSuccess} />;
      case 'ShowStore':
        return <ShowStore storeIdToShow={storeIdToShow} />;
      case 'FreeMarketOrder':
        return <FreeMarketOrder />


      default:
        return null;
    }
  };

  return (
    <>
      <main>
        <div className="navbarWelcome">
          <div className="container">
            <NavBar />
          </div>
        </div>


        <MenuMobile />

        <div className="container">
          <section className="global">
            <Aside onComponentClick={handleComponentClick} />
            <section className='center'>
              {loading ? (
                <div className="loading-spinner">
                  <BarLoader color="#36D7B7" loading={loading} />
                  <p>Carregando...</p>
                </div>
              ) : renderComponent()
              }
            </section>
          </section>
        </div>

      </main>
    </>
  )
}

export default Welcome