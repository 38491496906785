import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import Private from './context/private';

import './App.css';


import FixedPage from './pages/LandingPage/FixedPage/FixedPage';
import Login from './pages/Login/Login';
import Welcome from './pages/Welcome/Welcome';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import Home from './pages/Home/Home';
import MulterUpload from './pages/MulterUpload/MulterUpload';

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Routes>
            <Route
              exact path="/"
              element={
                <div className="App">
                  <FixedPage />
                </div>
              }
            />
            {/* Rotas abertas */}
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgetPassword" element={<ForgetPassword />} />
            <Route exact path="/prototype-landingpage" element={<Home />} />
            {/* Rotas protegidas do frontend */}
            <Route exact path="/welcome" element={<Private><Welcome /></Private>} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </AuthProvider>
      </Router>
      <Router>
        <Routes>
          <Route path="/multerupload/*" element={<MulterUpload />} />
        </Routes>
        <Routes>
          <Route path="/proposta-01/*" />
        </Routes>
      </Router>
    </>
  );
}

export default App;