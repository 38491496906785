import React, { useState } from 'react';
import { createUploadFiles } from '../../../services/api';
import { useNavigate } from 'react-router-dom';

import '../styles/Register.css';

function Register() {
  const [username, setUsername] = useState("");
  const [userFile, setUserFile] = useState("");
  const [selectedLogo, setSelectedLogo] = useState('/img/logo_default.png');

  const navigate = useNavigate();

  const setData = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
  }

  const setImgFile = (e) => {
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      setUserFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };
      reader.readAsDataURL(file); // Alteração aqui
    }
  }

  const addUserData = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("uploadimg", userFile);
    formData.append("username", username);

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }

    try {
      await createUploadFiles(formData, config);
      console.log(userFile, username);
      navigate('/multerupload');

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="container">
      <section className="upload-img">
        <h2>Upload de Imagem - Adicione sua imagem aqui</h2>
        <form className='formUploadImg'>
          <div className="group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" className="register-username" name="username" onChange={setData} />
          </div>
          <div className="group">
            <label htmlFor="uploadimg">Selecione uma imagem</label>
            <input
              type="file"
              id="uploadimg"
              className="register-username"
              name="uploadimg"
              onChange={setImgFile}
            />
            <img className='selectedLogo' src={selectedLogo} alt="logotipo" />
          </div>
          <div className="btnUploadImg">
            <button className="btn-upload-img" onClick={addUserData} >Enviar</button>
          </div>
        </form>
      </section>
    </div>
  )
}

export default Register