import React from 'react';
import { NavLink } from 'react-router-dom';


function Header() {
  return (

    <nav className='navUploadImg'>
      <div className="container">
        <div className='navbarUploadImg'>
          <div className="navbrandUploadImg">
            <NavLink to="/multerupload" end>
              <img src="/img/logo_default.png" alt="Logo" />
            </NavLink>
          </div>
          <div className="navlinkUploadImg">
            <NavLink to="/multerupload" end>
              Home
            </NavLink>
            <NavLink to="/multerupload/register">
              Register
            </NavLink>
            <NavLink to="/multerupload/list">
              List
            </NavLink>
            <span className='asideLink'> <a href="/welcome">Welcome</a></span>
          </div>
        </div>

      </div>
    </nav >


  )
}

export default Header