import React from 'react'

import '../styles/ListUpload.css';

function List() {
  return (
    <div className="container">
      <section className="listMain">
        <div>List</div>
      </section>
    </div>
  )
}

export default List