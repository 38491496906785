import React from 'react'
import '../CardStore/CardStore.css';

function CardStore(props) {
  return (

    <>
      <div className="card">
        <div className="cardheadBkg"></div>
        <div className="cardhead">
          <img src="img/aicaalogo.ico" alt="aicaa.logo" />
          <h4>{props.nomeDoCei}</h4>
        </div>
        <div className="cardCnpj">{props.cnpj}</div>
        <section className='cardBlock'>
          <div className='cardRow'>
            <span className=''>Endereço </span>
            <div className="">
              <a href={`https://www.google.com/maps?q=${props.address}`}
                target="_blank"
                rel="noopener noreferrer">{props.address}</a>
            </div>
          </div>
          <section className="cardBlock">
            <div className="cardRow">
              <span className=''>Telefones </span>
              <div className="cardBlock">
                {props.phoneNumber1}<br />
                {props.phoneNumber2}<br />
                {props.phoneNumber3}<br />
                {props.phoneNumber4}
              </div>
            </div>
          </section>
          <section className="cardBlock">
            <div className="cardRow">
              <span>Email </span>
              <div className="email">
                <a href={`mailto:${props.email}`}>{props.email}</a>
              </div>
            </div>
          </section>

          <section className="cardBlock">
            <div className="cardRow">
              <span>Contatos</span>
            </div>
            <div className="cardRow">
              <div className="contact">
                <img src="img/avatar.png" alt="avatar" />
                <span htmlFor="contact1">{props.contact}</span>
              </div>
              <div className='contactLink'>
                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/whatsappIco.png" alt="whatsapp icon" /></a>

                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/phoneIco.png" alt="phoneNumber icon" /></a>

                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/emailIco.png" alt="email icon" /></a>
              </div>
            </div>

            <div className="cardRow">
              <div className="contact">
                <img src="img/avatar.png" alt="avatar" />
                <span htmlFor="contact1">{props.contact2}</span>
              </div>
              <div className='contactLink'>
                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/whatsappIco.png" alt="whatsapp icon" /></a>

                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/phoneIco.png" alt="phoneNumber icon" /></a>

                <a href={`https://api.whatsapp.com/send?phone=551125038423`}
                  target="_blank"
                  rel="noopener noreferrer"><img className='contactIco' src="./img/emailIco.png" alt="email icon" /></a>
              </div>
            </div>

          </section>

          <section className="cardBlock">
            <div className="cardRow">
              <span>Atendimentos desta unidade</span>
            </div>
            <section className="services">
              <div className="serviceType">Total de Atendimentos: {props.totalServices}</div>
              <div className="serviceType">{props.serviceName1}: {props.totalServiceName1} </div>
              <div className="serviceType">{props.serviceName2}: {props.totalServiceName2} </div>
              <div className="serviceType">{props.serviceName3}: {props.totalServiceName3} </div>
            </section>
          </section>
        </section>
      </div>
    </>
  )
}

export default CardStore