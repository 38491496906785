import React, { useRef } from 'react'

import '../Section3/Section3.css';

function Section3() {
  const section3Ref = useRef(null);

  return (
    <>
      <div ref={section3Ref} id="section3" className="section3">
        <div className='main'>

          <h2>Você pode ajudar de muitas formas</h2>
          <strong>Entre em contato conosco</strong>

          <form className='contact-Us' action="">
            <div className='group-Us'>
              <label htmlFor="email-Us">Digite seu email</label>
              <input type="email" className='email-Us' id='email-Us' />
            </div>
            <div className='group-Us'>
              <label htmlFor="message-Us">Mensagem</label>
              <input type="area" className='message-Us' id='message-Us' />
            </div>

            <div className="btn-Us">
              <button className='' type='submit'>Enviar</button>
            </div>
          </form>
          <h3>Faça uma doação</h3>
          <div className='bank-Us'>
            <span>Banco do Brasil</span>
            <span>Agência: 300-X</span>
            <span>Conta Corrente: 000-000</span>
            <span>Chave-Pix: 2222-2222</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section3