import React, { useState, useEffect } from 'react';
import { BarLoader } from "react-spinners";
import { getStoreById } from '../../services/api';
import { FcPhoneAndroid } from "react-icons/fc";

import { FcPrint } from 'react-icons/fc';
import storePdf from '../../utils/Pdf/StorePdf';
import storeEmail from '../../utils/Email/StoreEmail';

import '../Store/styles/ShowStore.css';

function ShowStore({ storeIdToShow }) {
  const [store, setStore] = useState({});
  const [loading, setLoading] = useState(true);
  const id = storeIdToShow;

  useEffect(() => {
    (async () => {
      try {
        const response = await getStoreById(id);
        const storeData = response.data[0];
        setStore(storeData);
        console.log("Dados da Loja:", storeData);
        // Defina o selectedCompanyId aqui
        setStore({
          store_id: storeData.store_id || "",
          company_name: storeData.company_name || "",
          store_name: storeData.store_name || "",
          fantasy_name: storeData.fantasy_name || "",
          company_type: storeData.company_type || "",
          store_cnpj: storeData.store_cnpj || "",
          inscEstadual: storeData.inscEstadual || "",
          inscMunicipal: storeData.inscMunicipal || "",
          contact: storeData.contact || "",
          contact2: storeData.contact2 || "",
          phone: storeData.phone || "",
          phone2: storeData.phone2 || "",
          mobile: storeData.mobile || "",
          mobile2: storeData.mobile2 || "",
          website: storeData.website || "",
          email: storeData.email || "",
          socialMedia: storeData.socialMedia || "",
          socialMedia2: storeData.socialMedia2 || "",
          socialMedia3: storeData.socialMedia3 || "",
          banner_url: storeData.banner_url || "",
          zipcode: storeData.zipcode || "",
          street: storeData.street || "",
          number: storeData.number || "",
          complement: storeData.complement || "",
          neighborhood: storeData.neighborhood || "",
          city: storeData.city || "",
          state: storeData.state || ""
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);

  const handlePdf = (store) => {
    storePdf(store);
  };

  const handleEmail = (pdfBlob) => {
    // const pdfBlob = storePdf(store);
    storeEmail(pdfBlob);
  };


  return (

    <>
      <div className='showInfoTitle'><label>Centro de Educação Infântil: </label><span>{store.fantasy_name}</span></div>
      {loading ? (
        <div className="loading-spinner">
          <BarLoader color="#36D7B7" loading={loading} />
          <p>Carregando...</p>
        </div>
      ) : (
        <>
          <section className="showInfo">
            <div className="showLeft">
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Matriz</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.company_name}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Razão Social</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.store_name}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Nome Fantasia</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.fantasy_name}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>CNPJ</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.store_cnpj}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Tipo de empresa</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.company_type}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Inscrição Estadual</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.inscEstadual}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Inscrição Municipal</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.inscMunicipal}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <h4>Contato</h4>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Contatos</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.contact}   |   </span><span>{store.contact2}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Telefones</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.phone}</span>
                  <span>{store.phone2}</span>
                  <span>{store.phone2}</span>
                  <span>{store.phone2}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Mídias Sociais</label>
                </div>
                <div className="lineBoxB">
                  <a className='medias' href="/"> <FcPhoneAndroid className='asideIco' /> <span>{store.socialMedia}</span></a>
                  <a className='medias' href="/"> <FcPhoneAndroid className='asideIco' /> <span>{store.socialMedia2}</span></a>
                  <a className='medias' href="/"> <FcPhoneAndroid className='asideIco' /> <span>{store.socialMedia3}</span></a>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Email</label>
                </div>
                <div className="lineBoxB">
                  <label>{store.email}</label>
                </div>
              </div>
              <div className='showInfoLines'>
                <h4>Endereço</h4>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Endereço</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.street}, {store.number} - {store.complement}</span>
                </div>
              </div>
              <div className='showInfoLines'>
                <div className="lineBoxA">
                  <label>Bairro /Cidade /CEP</label>
                </div>
                <div className="lineBoxB">
                  <span>{store.neighborhood} - {store.city} - {store.state} - {store.zipcode}</span>
                </div>
              </div>
            </div>
            <div className="showRight">
              <div className="banner_url">
                {store.banner_url === !store.banner_url || store.banner_url === "" || store.banner_url === "" ? (
                  <img className='bannerCei' src="../img/cei_default_banner.png" alt="banner padrão do cei" />
                ) : (
                  <img className='bannerCei' src={store.banner_url} alt="banner do cei" />
                )}
              </div>
            </div>
          </section>
          <div className="actions">
            <div>
              <button className="printBtn">

                <FcPrint className='asideIco' />

                <span>imprimir</span>

              </button>
            </div>
            <button className='printBtn' onClick={() => handlePdf(store)}>
              <FcPrint className='asideIco' />
              <span>pdf</span>
            </button>
            <button className='printBtn' onClick={() => handleEmail(store)}>
              <FcPrint className='asideIco' />
              <span>email</span>
            </button>
          </div>

        </>
      )
      }
    </>

  )
}

export default ShowStore