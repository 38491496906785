// Carousel.js

import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../,,/../Carousel/Carousel.css';

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };

  return (
    <Slider {...settings}>
      <div className='carouselSlide'>
        <div className="banText">
          <h1>Banner 1 - Title</h1>
          <p>lorem ipsum</p>
        </div>
        <img className='imgBanner' src="/img/banner-educa5.jpg" alt="imagem 1 banner" />
      </div>
      {/* <div className='carouselSlide'>
        <img className='imgBanner' src="/img/banner-educa2.jpg" alt="imagem 1 banner" />
      </div>
      <div className='carouselSlide'>
        <img className='imgBanner' src="/img/ceifeliz1-team.jpeg" alt="imagem 1 banner" />
      </div>
      <div className='carouselSlide'>
        <img className='imgBanner' src="/img/bckgLogin.png" alt="imagem 2 banner" />
      </div>
      <div className='carouselSlide'>
        <img className='imgBanner' src="/img/acao-social1.png" alt="imagem 3 banner" />
      </div>
      <div className='carouselSlide'>
        <img className='imgBanner' src="/img/aicaalogoG.png" alt="imagem 4 banner" />
      </div> */}
    </Slider>
  );
};

export default Carousel;
