import React, { useState, useEffect } from 'react'
import CardStore from '../../components/CardStore/CardStore'
import SearchBar from '../Welcome/components/SearchBar';
import CardMobile from '../../components/CardMobile/CardMobile';

import '../Welcome/Welcome.css';

import { getStoreContract } from '../../services/api';

function GetStoreContract() {
  const [searchText, setSearchText] = useState("");
  const [contract, setContract] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getStoreContract();
      const contractData = response.data;
      setContract(contractData);
      // Atualize os resultados filtrados quando o searchText for alterado
      const filteredContracts = getFilteredContracts();
      setFilteredContracts(filteredContracts)
    })();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    const filteredContracts = getFilteredContracts();
    setFilteredContracts(filteredContracts);
  };

  const getFilteredContracts = () => {
    return contract.filter((contracts) => {
      const contractInfo = `${contracts.store_name} ${contracts.store_cnpj} ${contracts.street}`;
      const searchTextLowerCase = searchText.trim().toLowerCase();
      return contractInfo.toLowerCase().includes(searchTextLowerCase);
    });
  };



  return (

    <>
      <SearchBar onSearch={handleSearch} />
      <section className="content">
        {(searchText === '' ? contract : filteredContracts).map((contracts) =>
        (
          <CardStore
            key={contracts.contract_id}
            nomeDoCei={contracts.store_name}
            cnpj={contracts.store_cnpj}
            address={`${contracts.street}, ${contracts.number}`}
            phoneNumber1={contracts.phone}
            phoneNumber2={contracts.phone2}
            phoneNumber3={contracts.mobile}
            phoneNumber4={contracts.mobile2}
            email={contracts.email}
            contact={contracts.contact}
            contact2={contracts.contact2}
            totalServices={contracts.total_served}
            serviceName1={contracts.service_type}
            totalServiceName1={contracts.number_served}
            serviceName2={contracts.service_type2}
            totalServiceName2={contracts.number_served2}
            serviceName3={contracts.service_type3}
            totalServiceName3={contracts.number_served3}
          />
        ))}
      </section>
      <section className="content">
        {(searchText === '' ? contract : filteredContracts).map((contracts) =>
        (
          <CardMobile
            key={contracts.contract_id}
            nomeDoCei={contracts.store_name}
            cnpj={contracts.store_cnpj}
            address={`${contracts.street}, ${contracts.number}`}
            phoneNumber1={contracts.phone}
            phoneNumber2={contracts.phone2}
            phoneNumber3={contracts.mobile}
            phoneNumber4={contracts.mobile2}
            email={contracts.email}
            contact={contracts.contact}
            contact2={contracts.contact2}
            totalServices={contracts.total_served}
            serviceName1={contracts.service_type}
            totalServiceName1={contracts.number_served}
            serviceName2={contracts.service_type2}
            totalServiceName2={contracts.number_served2}
            serviceName3={contracts.service_type3}
            totalServiceName3={contracts.number_served3}
          />
        ))}
      </section>
    </>
  )

}

export default GetStoreContract
