import React, { useState, useEffect } from 'react';

import '../NavBar.css';

import Logout from './Logout';

function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Adjust threshold as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll); // Cleanup
  }, []);

  return (
    <>

      <nav className={isScrolled ? 'scrolled' : ''}> {/* Apply 'scrolled' class conditionally */}

        <img src="./img/aicaalogo.ico" alt="" />
        <div className="navTitle">
          Associação Infância com Alimento e Afeto
        </div>
        <ul>
          <li><Logout /></li>
        </ul>
      </nav>
    </>
  )
}

export default NavBar