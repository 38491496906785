import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './auth';

const Private = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  // Redirecionar usuários autenticados para a página inicial se tentarem acessar a página de login novamente
  if (authenticated && window.location.pathname === '/login') {
    return <Navigate to="/" />;
  }

  return <div>{children}</div>;
};

export default Private;
