import React from 'react'

import '../styles/CardUploadImg.css';

function Card({ id_userData, userimg, username, date, onDeleteUser }) {
  const deleteUserData = () => {
    // Passa o ID do usuário para a função de exclusão definida no componente pai
    onDeleteUser(id_userData);
  };
  return (
    <>
      <div className="cardUploadImg">
        <div className="cardheaderUploadImg">
          <img src={userimg} className='avatarcardUploadImg' alt="imagem avatar" />
        </div>
        <div className="cardbodyUploadImg">
          <div className="cardtitlename">Username: {username}</div>
          <div className="carddataadd">Date Added: {date}</div>
        </div>
        <div className="cardbuttonUploadImg">
          <button onClick={deleteUserData} className='btncardUploadImg'>Delete</button>
        </div>
      </div>
    </>
  )
}

export default Card
