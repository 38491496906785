import React, { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { api, createSession } from '../services/api';

import Cookies from 'js-cookie';

export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = Cookies.get('user');
    const token = Cookies.get('token');

    if (recoveredUser && token) {
      setLoginUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = `Bearer ${token}`
    }
    setLoading(false);
  }, []);


  const login = async (username, password) => {
    const response = await createSession(username, password);
    const loggedUser = response.data;
    const token = response.data.token;

    Cookies.set('user', JSON.stringify({ username: loggedUser.username, password: loggedUser.password }));
    Cookies.set('token', token);
    api.defaults.headers.Authorization = `Bearer ${token}`;

    setLoginUser({ username: loggedUser.username, password: loggedUser.password });
    navigate("/welcome");
  }

  const logout = () => {
    Cookies.remove('user');
    Cookies.remove('token');
    api.defaults.headers.Authorization = null;
    setLoginUser(null);
    navigate("/login");
  }


  return (

    <AuthContext.Provider value={{ authenticated: !!loginUser, loginUser, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider