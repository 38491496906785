import React, { useContext } from 'react'
import { AuthContext } from '../../../context/auth'
// import { MdPlayCircle } from 'react-icons/md';
import { FcBrokenLink } from 'react-icons/fc';



export default function Logout() {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  }

  return (
    <button className='btnLogout' onClick={handleLogout}><span>logout</span><span className='logoutIco'><FcBrokenLink className="icons logoutIco" /></span></button>
  )
}
