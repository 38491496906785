import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../styles/MulterUpload.css';
import Card from './Card';
import { deleteUploadFiles, getUploadFiles } from '../../../services/api';


function Default() {
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const response = await getUploadFiles();
      const dataUser = response.data;
      console.log(dataUser)
      setUserData(dataUser);
    })();
    // eslint - disable - next - line
  }, []);

  const onDeleteUser = async (id_userData) => {
    try {
      console.log('delete id : ' + id_userData);
      await deleteUploadFiles(id_userData);
      alert(`Removido o registro ID: ${id_userData} com sucesso :)`);

      // Atualiza a lista após a exclusão
      const updatedData = userData.filter(user => user.id_userData !== id_userData);
      setUserData(updatedData);
    } catch (error) {
      console.error('Erro ao excluir:', error);
      alert(`Erro ao tentar deletar o registro: ${error}`);
    }
  };

  const onClickAddUser = () => {
    navigate('/multerupload/register');
  }

  return (
    <>
      <div className="container">
        <div className="add-user">
          <button onClick={onClickAddUser} className='btn-add-user'>add user +</button>
        </div>
        <div className="counterRegister">
          <p>Total de Registros: {userData.length}</p>
        </div>
        <section className="home-cards">

          {userData.length > 0 ?
            userData.map(user => (
              <Card
                key={user.id_userData}
                id_userData={user.id_userData}
                userimg={user.userimg ? user.userimg : "/img/avatar.png"}
                username={user.username}
                date={moment(user.date).format("DD/MM/YYYY")}
                onDeleteUser={onDeleteUser}
              />
            )) : <h4>Nenhum registro para mostar :(</h4>}
        </section>
      </div>
    </>
  )
}

export default Default