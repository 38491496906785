import React from 'react'
import Flag from '../../../components/Flag/Flag';

import '../FixedPage/FixedPage.css';

function FixedPage() {
  return (
    <div className="title">
      <h2>fale conosco</h2>
      <Flag ico={'img/phoneIco.png'} text="(11) 2503 - 8423" />
      <Flag propsLink="https://api.whatsapp.com/send?phone=551125038423" target="_blank" ico={'img/whatsappIco.png'} text="(11) 2503 - 8423" />
      <Flag propsLink="mailto:contato@aicaa.com.br" target="_blank" ico={'img/emailIco.png'} text="contato@aicaa.com.br" />
    </div>
  )
}

export default FixedPage