import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/auth';

import './Login.css';

function Login() {
  const { login } = useContext(AuthContext);

  const [message, setMessage] = useState('');

  const [loginUser, setLoginUser] = useState({
    username: '',
    password: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(loginUser.username, loginUser.password);
    } catch (error) {
      setMessage('Usuário ou senha incorretos');
      ressetMessage();
    }
  };

  const ressetMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleChange = (e) => {
    setLoginUser({
      ...loginUser,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    return () => {
      setMessage('');
    };
  }, []);

  return (
    <>
      <div className="mainLogin">
        {message && <span className="message"> {message}</span>}
        <form action="" onSubmit={handleSubmit} className='sendForm'>
          <div className="formgroup">
            <div className="colA">Usuário</div>
            <div className="colB">
              <input
                type="text"
                className='username'
                id="username"
                name="username"
                placeholder=""
                autoComplete="current-user"
                value={
                  loginUser.username
                }
                onChange={
                  handleChange
                }
              />
            </div>
          </div>
          <div className="formgroup">
            <div className="colA">Senha</div>
            <div className="colB"><input
              type="password"
              className="password"
              id="password"
              name="password"
              placeholder=""
              autoComplete="current-password"
              value={
                loginUser.password
              }
              onChange={
                handleChange
              }
            /></div>
          </div>
          <div className="sendBtn">
            <button className='send'>Enviar</button>
          </div>
          <div className="formgroup links">
            <div className="colA"><a href="/forgetPassword">esqueci a senha</a></div>
            <div className="colB"><a href="/">sair</a></div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;