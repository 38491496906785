import React, { useRef } from 'react';

import '../Footer/Footer.css';

function Footer() {

  const footer = useRef(null);

  return (
    <div ref={footer} id="footer" className='footer'><div className="footerTitle">Reserved All Rights</div></div>
  )
}

export default Footer