import React from 'react'
import '../Home/Home.css';


import NavBarSite from '../LandingPage/NavBarSite/NavBarSite.js';
import Banner from '../LandingPage/Banner/Banner.js';
import Section1 from '../LandingPage/Section1/Section1.js';
import Section2 from '../LandingPage/Section2/Section2.js';
import Section3 from '../LandingPage/Section3/Section3.js';
import Footer from '../LandingPage/Footer/Footer.js';

function Home() {

  return (
    <>
      <NavBarSite />
      <div className="main-content">
        <Banner />
        <Section1 />
        <Section2 />
        <Section3 />
        <Footer />
      </div>
    </>
  )
}

export default Home