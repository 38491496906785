import React from 'react'

import '../../components/CardMobile/CardMobile.css';

function MenuMobile({ onComponentClick }) {
  return (

    <>
      <section className="menuMobile">
        <div className="menuRow">
          <div className="menuLink"><span onClick={() => onComponentClick("CardStore")} >CEIs</span></div>
          <div className="menuLink"><a href="link1">Link 2</a></div>
          <div className="menuLink"><a href="link1">Link 3</a></div>
          <div className="menuLink"><a href="link1">Link 4</a></div>
          <div className="menuLink"><a href="link1">Link 5</a></div>
          <div className="menuLink"><a href="link1">Link 6</a></div>
          <div className="menuLink"><a href="link1">Link 7</a></div>
          <div className="menuLink"><a href="link1">Link 8</a></div>
          <div className="menuLink"><a href="link1">Link 9</a></div>
          <div className="menuLink"><a href="link1">Link 10</a></div>
        </div>
      </section>
    </>
  )
}

export default MenuMobile