import React, { useState } from 'react';

import '../../Welcome/Welcome.css';

function SearchBar({ onSearch }) {
  const [searchText, setSearchText] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchText);
  };


  return (
    <section className="welcomeSearch">
      <form onSubmit={handleSubmit} className="welcomeSearch">
        <input
          type="text"
          name="search"
          className="welcomeSearch"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Digite aqui sua pesquisa"
        />
        <button className="searchBtn" type="submit" >
          Buscar
        </button>
      </form>
    </section>
  );
}

export default SearchBar;
