import axios from "axios";

export const api = axios.create({
  baseURL: 'https://api.aicaa.com.br/',  //api produção
  //baseURL: 'http://localhost:3001/',   //api desenvolvimento
});

// create session
export const createSession = async (username, password) => {
  return api.post('/login', { username, password });
}

// Stores
export const getStore = async () => {
  return api.get('/store');
}

export const getStoreById = async (store_id) => {
  return api.get(`/store/${store_id}`);
}

// export const upload = async (formDataToSend) => {
//   const config = {
//     headers: { 'Content-Type': 'multipart/form-data' },
//   };
//   return api.post('/store/upload/', formDataToSend, config);
// };

export const createStore = async (formData, banner_url) => {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };
    const response = await api.post('/store/', formData, banner_url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const createStore = async (formData, formDataToSend, file) => {

//   const config = {
//     headers: { 'Content-Type': 'multipart/form-data' },
//   };

//   return api.post('/store', formData, formDataToSend, file, config);
// }

export const updateStore = async (store_id, formData, banner_url) => {
  return api.put(`/store/${store_id}`, formData, banner_url);
}

export const deleteStore = async (store_id) => {
  return api.delete(`/store/${store_id}`);
}

// Contract - Convênios

export const getStoreContract = async () => {
  return api.get('/contract');
}

export const getStoreContractById = async (store_id) => {
  return api.get(`/contract/:${store_id}`);
}

// Users
export const getUser = async () => {
  return api.get('/user');
}

export const listUser = async () => {
  return api.get('/user/list');
}

export const createUser = async () => {
  return api.post('/user')
}

//Company API
export const getCompany = async () => {
  return api.get('/company');
}

//Register - Upload Image
export const createUploadFiles = async (formData, config) => {
  return api.post('/uploadFiles', formData, config)
}

export const getUploadFiles = async () => {
  return api.get('/uploadFiles');
}

export const deleteUploadFiles = async (id) => {
  return api.delete(`/uploadFiles/${id}`);
}