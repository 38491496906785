import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Header from './components/Header';
import Default from './components/Default';
import List from './components/List';

import './styles/MulterUpload.css';

function MulterUpload() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Default />} />
        <Route path="/register" element={<Register />} />
        <Route path="/list" element={<List />} />
      </Routes>
    </>
  )
}

export default MulterUpload