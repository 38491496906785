import React from 'react'

function ModalForms({ message, onClose }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}></span>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default ModalForms