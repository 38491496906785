import React, { useRef } from 'react'

import '../Section1/Section1.css';

function Section1() {

  const section1Ref = useRef(null);

  return (

    <div className="main-content">

      <div ref={section1Ref} id="section1" className='section1'>
        <div className="sec1Main">
          <div className="sec1Slogan">
            <p>
              Somos a Associação Infância com Alimento e Afeto,<br /> presentes e atuantes dispostos a fazer a diferença.<br />
              <p>
                Em nosso compromisso pela mudança cada gesto é uma expressão de inclusão.
                <br />
                Vem com a gente!
              </p>
            </p>
          </div>
          <div className="sec1Text">"A força da<br /><span>comunidade</span><br />está na ajuda mútua"</div>
          <div className="sec1A">
            <div className="img">
              <img src="../img/imgSec1A.png" alt="" />
            </div>
          </div>
          <div className="sec1B"></div>
          <div className="sec1Footer">
            <div className="sec1ColABC">
              <img src="../img/sec1FooterBkg.png" alt="" />
              <span> Projetos Sociais</span>
            </div>
            <div className="sec1ColABC">
              <img src="../img/sec1FooterBkg.png" alt="" />
              <span> Projetos Culturais</span>
            </div>
            <div className="sec1ColABC">
              <img src="../img/sec1FooterBkg.png" alt="" />
              <span> Projetos Educacionais</span>
            </div>
            <div className="sec1ColABC">
              <img src="../img/sec1FooterBkg.png" alt="" />
              <span> Projetos Esportivos</span>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default Section1