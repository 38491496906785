import React, { useRef } from 'react'

import '../Section2/Section2.css';

function Section2() {
  const section2Ref = useRef(null);
  return (
    <div ref={section2Ref} id="section2" className='section2'>
      <div className="sec2Bkg">
        <img src="../img/capoeira2.png" alt="img background seção 2" />
      </div>
      <div className="fotPlay">
        <img src="../img/footbalplayer2.png" alt="img background seção 2" />
      </div>

    </div>
  )
}

export default Section2