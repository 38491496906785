// NavBar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../NavBarSite/NavBarSite.css';

function NavBarSite() {
  const navigate = useNavigate();

  function handleLogin() {
    navigate('/login');
  }

  return (
    <div className='navSite'>
      <div className="logo">
        <a href="#banner"><img src="./img/aicaa.png" alt="logo-nav" /></a>
      </div>
      <ul>
        <li><a href="#banner">Home</a></li>
        <li><a href="#section1">Sobre Nós</a></li>
        <li><a href="#section2">Section2</a></li>
        <li><a href="#section3">Section3</a></li>
        <li><a href="#footer">Footer</a></li>
      </ul>
      <div className="log" onClick={handleLogin}>login</div>
    </div>
  );
}

export default NavBarSite;
