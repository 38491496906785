import React, { useEffect, useState } from 'react';
import { BarLoader } from "react-spinners";
import axios from "axios";

import '../Store/styles/Store.css';

import { getCompany, getStoreById, updateStore } from '../../services/api';
import ModalForms from '../Welcome/components/ModalForms';


function UpdateStore({ storeIdToUpdate, onUpdateSuccess }) {
  const [message, setMessage] = useState("");
  const [messageCep, setMessageCep] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [loading, setLoading] = useState(true);
  const [, setStoreName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [, setSelectedComponent] = useState([]);
  const [file, setFile] = useState("");
  const [selectedLogo, setSelectedLogo] = useState(null);
  const id = storeIdToUpdate;

  // console.log("este é o id recebido da lista:", id);

  const [formData, setFormData] = useState({
    store_name: "",
    company_id: "",
    fantasy_name: "",
    company_type: "",
    cnpj: "",
    inscEstadual: "",
    inscMunicipal: "",
    contact: "",
    contact2: "",
    phone: "",
    phone2: "",
    mobile: "",
    mobile2: "",
    website: "",
    email: "",
    socialMedia: "",
    socialMedia2: "",
    socialMedia3: "",
    banner_url: "",
    address: {
      zipcode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      store_id: ""
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getStoreById(id);
        const storeData = response.data[0];
        console.log("Dados da Loja vindos do banco:", storeData);
        // Defina o selectedCompanyId aqui
        setSelectedCompanyId(storeData.company_id || "");

        setFormData({
          store_id: storeData.store_id,
          store_name: storeData.store_name || "",
          company_id: storeData.company_id || "",
          fantasy_name: storeData.fantasy_name || "",
          company_type: storeData.company_type || "filial",
          cnpj: storeData.store_cnpj || "",
          inscEstadual: storeData.inscEstadual || "",
          inscMunicipal: storeData.inscMunicipal || "",
          contact: storeData.contact || "",
          contact2: storeData.contact2 || "",
          phone: storeData.phone || "",
          phone2: storeData.phone2 || "",
          mobile: storeData.mobile || "",
          mobile2: storeData.mobile2 || "",
          website: storeData.website || "",
          email: storeData.email || "",
          socialMedia: storeData.socialMedia || "",
          socialMedia2: storeData.socialMedia2 || "",
          socialMedia3: storeData.socialMedia3 || "",
          banner_url: storeData.banner_url || "",
          address: {
            zipcode: storeData.zipcode || "",
            street: storeData.street || "",
            number: storeData.number || "",
            complement: storeData.complement || "",
            neighborhood: storeData.neighborhood || "",
            city: storeData.city || "",
            state: storeData.state || "",
            store_id: storeData.store_id || ""
          }
        });
        setSelectedLogo(storeData.banner_url || "./img/logo_default.png");
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id])

  useEffect(() => {
    (async () => {
      const response = await getCompany();
      const company = response.data;
      setCompanies(company);
      setLoading(false);
    })();
  }, []);


  const handleCEPChange = async (e) => {
    e.preventDefault();
    const cep = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const addressData = response.data;

        document.getElementById('zipcode').value = addressData.cep;
        document.getElementById('street').value = addressData.logradouro;
        document.getElementById('neighborhood').value = addressData.bairro;
        document.getElementById('city').value = addressData.localidade;
        document.getElementById('state').value = addressData.uf;

        if (!addressData.erro) {
          setFormData((prevData) => ({
            ...prevData,
            address: {
              ...prevData.address,
              zipcode: addressData.cep || prevData.zipcode || "",
              street: addressData.logradouro || "",
              neighborhood: addressData.bairro || "",
              city: addressData.localidade || "",
              state: addressData.uf || "",
              number: prevData.address.number || '',
              complement: prevData.address.complement || '',
            },
          }));

          //Preencher os campos de número e complemento se disponíveis
          if (addressData.numero) {
            setFormData((prevData) => ({
              ...prevData,
              address: {
                ...prevData.address,
                number: addressData.numero,
              },
            }));
          }

          if (addressData.complemento) {
            setFormData((prevData) => ({
              ...prevData,
              address: {
                ...prevData.address,
                complement: addressData.complemento,
              },
            }));
          }
        } else {
          setMessageCep('CEP não encontrado');
          setTimeout(() => {
            setMessageCep('');
          }, 2500);

        }
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    }
  };

  const handleOptionChange = (e) => {
    e.preventDefault();

    const selectedOption = e.target.options[e.target.selectedIndex];
    const companyId = selectedOption.getAttribute('data-id');
    const companyName = selectedOption.getAttribute('data-name');

    setSelectedCompanyId(companyId);
    setStoreName(companyName);

    setFormData((prevData) => ({
      ...prevData,
      company_id: companyId,
      company_fantasy_name: companyName,
    }));
  }


  // const handleAddressChange = (e) => {
  //   e.preventDefault();
  //   // Atualizar campos específicos do endereço
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     address: {
  //       ...prevData.address,
  //       [e.target.name]: e.target.value,
  //     },
  //   }));
  // };
  const handleAddressChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("name:", name);
    console.log("value:", value);

    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [e.target.name]: e.target.value,
      },
    }));
  };



  useEffect(() => {
    const form = document.querySelector("form.updateStore");
    // Adiciona um efeito para resetar o formulário quando selectedCompanyId for atualizado
    if (selectedCompanyId === "") {
      form.reset();
    }
  }, [selectedCompanyId]);


  // const handleChange = (e) => {
  //   e.preventDefault();

  //   if (e.target.name === "banner_url") {
  //     // Se o evento é relacionado ao campo de upload de imagem
  //     const file = e.target.files[0]; // Pega o arquivo
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Atualiza o estado com a URL da imagem
  //       setSelectedLogo(reader.result);
  //     };
  //     if (file) {
  //       reader.readAsDataURL(file); // Lê o arquivo como uma URL de dados
  //       console.log(file);
  //     }
  //     setFile(file);
  //   }
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [e.target.name]: e.target.value,
  //   }))
  // };

  const handleChange = (e) => {
    e.preventDefault();

    if (e.target.name === "banner_url") {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
        // Adiciona a propriedade 'required' se um arquivo foi selecionado
        document.getElementById('banner_url').setAttribute('required', 'required');
      } else {
        // Remove a propriedade 'required' se nenhum arquivo foi selecionado
        document.getElementById('banner_url').removeAttribute('required');
      }

      setFile(file);
    }

    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Verifica se os campos obrigatórios estão preenchidos
    if (!formData.store_name || !formData.company_id || !formData.cnpj || !formData.email) {
      setMessage(`Preencha todos os campos obrigatórios:
      Matriz, 
      Razão Social, 
      Nome Fantasia, 
      cnpj 
      e email`);
      setShowModal(true);
      setTimeout(() => {
        setMessage("");
        setShowModal(false);
      }, 2500);
      return; // Impede o envio do formulário se algum campo obrigatório estiver vazio
    }
    // Atualizar campos específicos do endereço
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [e.target.name]: e.target.value,
      },
    }));

    try {
      const formDataToSend = new FormData();
      // Adicionar campos de texto ao FormDataToSend
      Object.entries(formData).forEach(([key, value]) => {
        if (key !== 'banner_url') {
          formDataToSend.append(key, value);
        }
      })
      formDataToSend.append('banner_url', file);

      await updateStore(id, formData, file);

      setMessage('Registro alterado com sucesso');
      setShowModal(true);
      setTimeout(() => {
        setMessage('');
        setShowModal(false);
        onUpdateSuccess(id);
      }, 2500);

      setSelectedCompanyId("");

      setSelectedComponent("GetStoreById.formDataToSend.store_id");

    } catch (error) {
      setMessage("Erro ao tentar editar o registro :(");
      setShowModal(true);
      setTimeout(() => {
        console.log(error);
        setMessage("");
        setShowModal(false);
      }, 2500);
    }
  };


  return (
    <>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Conteúdo da sua modal */}
            {message && <ModalForms message={message} onClose={() => setShowModal(false)} />}
          </div>
        </div>)}

      <h4>Editar Registro</h4>
      <section className="createStores">
        <form className="updateStore">
          <div className="form-group">
            <h4>Dados da empresa</h4>
            <div className="group">
              <div id="store_id" className='store_id' name="store_id" onChange={handleChange} hidden>{formData.store_id}</div>
              <div className='colA'><label htmlFor='company'>Matriz - Razão Social</label></div>
              <div className='colB'>
                {loading ? (
                  <div className="loading-spinner">
                    <BarLoader color="#36D7B7" loading={loading} />
                    <p>Carregando...</p>
                  </div>
                ) : <select
                  className='selectList'
                  id='company'
                  value={selectedCompanyId}
                  onChange={handleOptionChange}
                  disabled
                >
                  <option value="" onChange={handleOptionChange}>Selecione uma empresa</option>
                  {companies.map((company) => (
                    <option
                      key={company.company_id}
                      value={company.company_id}
                      data-id={company.company_id}
                      data-name={company.company_name}
                    >
                      {company.company_name}
                    </option>
                  ))}
                </select>
                }
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="store_name">Razão Social</label></div>
              <div className='colB'><input type="text" id='store_name' name="store_name" value={formData.store_name} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'>
                <button className='chooseFileBtn'>
                  <label htmlFor="banner_url">Selecione: Logo / Foto / Banner</label>
                </button>
              </div>
              <div className='colB'>
                <input type="file"
                  className='banner_url'
                  id='banner_url'
                  name="banner_url"
                  onChange={handleChange}
                  required />
                <img className='selectedLogo' src={selectedLogo} alt="logotipo" />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="fantasy_name">Nome Fantasia</label></div>
              <div className='colB'><input type="text" id='fantasy_name' name="fantasy_name" value={formData.fantasy_name} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="cnpj">CNPJ</label></div>
              <div className='colB'>
                <input
                  name='cnpj'
                  type="text"
                  id="cnpj"
                  value={formData.cnpj}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="inscEstadual">Inscrição Estadual</label></div>
              <div className='colB'><input type="text" id='inscEstadual' name='inscEstadual' value={formData.inscEstadual} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="inscMunicipal">Inscrição Municipal</label></div>
              <div className='colB'><input type="text" id='inscMunicipal' name='inscMunicipal' value={formData.inscMunicipal} onChange={handleChange} /></div>
            </div>
          </div>

          <div className="form-group">
            <h4>Contatos</h4>
            <div className="group">
              <div className='colA'><label htmlFor="contact">Contato</label></div>
              <div className='colB'><input type="text" id='contact' name='contact' value={formData.contact} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="phone">Telefone</label></div>
              <div className='colB'>
                <input
                  name='phone'
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="mobile">Celular</label></div>
              <div className='colB'>
                <input
                  name="mobile"
                  type="text"
                  id="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="contact2">Contato 2</label></div>
              <div className='colB'><input type="text" id='contact2' name='contact2' value={formData.contact2} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="phone2">Telefone 2</label></div>
              <div className='colB'>
                <input
                  name='phone2'
                  type="text"
                  id="phone2"
                  value={formData.phone2}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="mobile2">Celular 2</label></div>
              <div className='colB'>
                <input
                  name='mobile2'
                  type="text"
                  id="mobile2"
                  value={formData.mobile2}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <h4>Web</h4>
            <div className="group">
              <div className='colA'><label htmlFor="website">Website</label></div>
              <div className='colB'><input type="text" id='website' name='website' value={formData.website} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="email">Email</label></div>
              <div className='colB'><input type="email" id='email' name='email' value={formData.email} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia' name='socialMedia' value={formData.socialMedia} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia2">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia2' name='socialMedia2' value={formData.socialMedia2} onChange={handleChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="socialMedia3">Social Media</label></div>
              <div className='colB'><input type="text" id='socialMedia3' name='socialMedia3' value={formData.socialMedia3} onChange={handleChange} /></div>
            </div>
          </div>

          <div className="form-group">
            <h4>Endereço</h4>
            <div className="group">
              <div className='colA'><label htmlFor="zipcode">CEP</label></div>
              <div className='colB'>
                <input
                  value={formData.address.zipcode}
                  onChange={handleCEPChange}
                  type="text"
                  id='zipcode'
                  name='zipcode'
                />
                {messageCep && <div className="message">{messageCep}</div>}
              </div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="street">Endereço</label></div>
              <div className='colB'><input type="text" id='street' name='street' value={formData.address.street} onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="number">Número</label></div>
              <div className='colB'><input type="text" id='number' name='number' value={formData.address.number} onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="complement">Complemento</label></div>
              <div className='colB'><input type="text" id='complement' name='complement' value={formData.address.complement} onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="neighborhood">Bairro</label></div>
              <div className='colB'><input type="text" id='neighborhood' name='neighborhood' value={formData.address.neighborhood} onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="city">Cidade</label></div>
              <div className='colB'><input type="text" id='city' name='city' value={formData.address.city} onChange={handleAddressChange} /></div>
            </div>
            <div className="group">
              <div className='colA'><label htmlFor="state">Estado</label></div>
              <div className='colB'><input type="text" id='state' name='state' value={formData.address.state} onChange={handleAddressChange} /></div>
            </div>
          </div>

          <div className="btnForm">
            <button className="bntForm" onClick={handleFormSubmit} >Enviar</button>
          </div>
          {/* {showModal && message && <div className="message modal">{message}</div>} */}
        </form>
      </section >
    </>
  )
}

export default UpdateStore