import jsPDF from 'jspdf';
import '../../pages/Store/styles/Store.css';

const storePdf = (store) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  let currentPage = "";

  const addHeader = () => {
    const imgData = '../../../img/aicaa.png';
    pdf.addImage(imgData, 'PNG', 10, 10, 20, 20);
    pdf.setFontSize(11);
    pdf.setTextColor(5, 0, 0);
    pdf.text(`${store.company_name}`, 35, 25);
    // pdf.line(6, 32, 200, 32);
  };

  const addFooter = () => {
    pdf.setTextColor(5, 0, 0);
    const rodapeYPosition = pdf.internal.pageSize.height - 5;
    pdf.setFontSize(6);
    pdf.text(`powered by`, 75, rodapeYPosition - 3, 'center');
    pdf.setFontSize(7);
    pdf.text(`apit solutions desenvolvimento de software ltda`, 105, rodapeYPosition, 'center');
    pdf.text(`${currentPage}`, 205, rodapeYPosition, 'center');
  };

  //Corpo do documento - start
  const addPageContent = () => {
    const maxPageHeight = pdf.internal.pageSize.height - 20;
    let yPosition = 50;
    addHeader();

    pdf.setTextColor(0, 0, 5);
    pdf.text(`${store.fantasy_name}`, 105, 38, 'center');

    // Adicionando imagem com base na extensão do arquivo
    if (store.banner_url) {
      if (store.banner_url.endsWith('.jpeg') || store.banner_url.endsWith('.jpg')) {
        pdf.addImage(store.banner_url, 'JPEG', 10, 30, 16, 16);
      } else if (store.banner_url.endsWith('.png')) {
        pdf.addImage(store.banner_url, 'PNG', 10, 30, 16, 16);
      } else if (store.banner_url.endsWith('.tiff')) {
        pdf.addImage(store.banner_url, 'TIFF', 10, 30, 16, 16);
      } else if (store.banner_url === '' || store.banner_url === undefined || store.banner_url === null) {
        const defaultImageUrl = '../../../img/uploads/avatar.png'; // Substitua pelo caminho da imagem padrão desejada
        pdf.addImage(defaultImageUrl, 'PNG', 10, 30, 16, 16);
      } else {
        // Se a extensão do arquivo não for reconhecida, não adiciona a imagem
        console.error('Formato de imagem não suportado:', store.banner_url);
      }
    }

    //Primeira box
    pdf.setLineWidth(0.2); // Espessura da linha
    pdf.setLineCap('round'); // Borda arredondada 
    // pdf.rect(6, 42, 196, 50); // x, y, largura, altura

    // Você pode adicionar mais detalhes conforme necessário

    //Labels configuração
    pdf.setFontSize(10);
    pdf.setTextColor(45, 75, 180);
    pdf.text(`Centro de Educação Infântil: `, 12, yPosition);
    pdf.text(`CNPJ: `, 12, yPosition + 6);
    pdf.text(`Inscrição Estadual: `, 82, yPosition + 6);
    pdf.text(`Inscrição Municipal: `, 132, yPosition + 6);
    pdf.text(`Email: `, 12, yPosition + 12);
    pdf.text(`Contatos: `, 12, yPosition + 18);
    pdf.text(`Telefones: `, 12, yPosition + 24);
    pdf.text(`Endereço: `, 12, yPosition + 30)

    // Dados (data) configuração
    pdf.setFontSize(10);
    pdf.setTextColor(5, 5, 80);
    pdf.text(`${store.fantasy_name}`, 58, yPosition);
    pdf.text(`${store.store_cnpj}`, 23, yPosition + 6);
    pdf.text(`${store.inscEstadual}`, 113, yPosition + 6);
    pdf.text(`${store.inscMunicipal}`, 164, yPosition + 6);
    pdf.text(`${store.email}`, 23, yPosition + 12);
    pdf.text(`${store.contact} | ${store.contact2}`, 28, yPosition + 18);
    pdf.text(`${store.phone}        ${store.phone2}        ${store.mobile}        ${store.mobile2}`, 30, yPosition + 24);
    pdf.text(`${store.street}, ${store.number} - ${store.complement} - ${store.neighborhood} - ${store.city} - ${store.state} - ${store.zipcode}`, 30, yPosition + 30);

    //Corpo do documento - end

    addFooter();

    // Adiciona uma nova página se necessário
    if (yPosition + 20 > maxPageHeight) {
      pdf.addPage();
      addHeader();
      addFooter();
    }
  };

  addPageContent();

  // pdf.save('documento.pdf');

  // Converte o PDF para um Blob
  const pdfBlob = pdf.output('blob');
  // Cria um URL para o Blob
  const pdfUrl = URL.createObjectURL(pdfBlob);
  // Abre o PDF no navegador
  window.open(pdfUrl, '_blank');

  return pdf;
};

export default storePdf;
